
<template>
  
  <div class="editRecipe">
    <div class="recipe">
      <div class="recipe-image">
        <img :src="recipe.image" />
      </div>


      <div class="edit-recipe-info">
        <div class="input">
          <label>Name</label>
          <input type="text" :placeholder="recipe.name" v-model="recipe.name" />
        </div>

        <div class="input">
          <label>Category</label>
          <input type="text" :placeholder="'Category'"  v-model="recipe.category"  />
        </div>

        <div class="ingredients">
          <h4 class="title">ingredients</h4>
          <div v-for="ingredient in recipe.ingredients" class="ingredient">


            <div class="input ingredient-name">
              <label>Name</label>
              <input type="text" :placeholder="ingredient.name" />
            </div>
            <div class="input calories">
              <label>Calories</label>
              <input class="" type="text" :placeholder="parseInt(ingredient.grams, 10)" />
            </div>
            <div class="input grams">
              <label>Grams</label>
              <input class="" type="text" :placeholder="parseInt(ingredient.calculatedCals, 10)" />
            </div>
          </div>

        </div>

      </div>

    </div>
    <div class="buttons">

      <button class="save-btn button" @click="updateRecipe()">Save</button>
    <button class="delete-btn button" @click="deleteRecipe()">Delete</button>

    </div>


  </div>
</template>

<script>

export default {
  name: 'EditRecipe',
  updated() {

  },
  data() {
    return {
      template:{

      }
    }
  },
  props: {
    recipe: Object
  },
  methods: {
    updateRecipe(){
      this.$store.commit('updateRecipe', this.recipe)
      console.log(this.recipe)
      //this.toggleNotification('Updated', true)
      this.$store.commit('toggleNotification', {text:'Updated',toggle: true})
    },
    deleteRecipe() {
      // this.$store.commit('removeRecipe', this.recipe)
      this.$panes['editRecipe'].moveToHeight(305)


      // this.hidePane('editRecipe');
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  font-size: 12px;
  font-weight: 500;
}



#editRecipe {
  // bottom: -581px;
  position: absolute;
  bottom: -1em;
  opacity: 0 //transform: translateY(-400px);
}



.editRecipe .recipe-image {
  height: 140px;
  width: 140px;
}

img {
  width: -webkit-fill-available;
  object-fit: cover;
}

.button {
  background: var(--gray-700);
  border: none;
  padding: 12px 12px;
  border-radius: 12px;
  font-size: 12px;
  color: var(--gray-50);
}

.input {
  margin-top: 8px;
}
input[type="text"] {
  border-radius: 1em;
  border: none;
  /* background: red; */
  padding: 12px 12px;
  border: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;
  /* text-align: center; */
}

label {
  font-weight: 500;
    padding-bottom: 4px;

    color: var(--gray-400);
}
.ingredients {
  display: flex;
  flex-direction: column;
  //gap: 8px;
  padding-bottom: 18px;
  margin-top: 2em;
  .ingredient {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-direction: row;

    label {}

    input {
      width: 100%;
    }


    .calories,
    .grams {
      text-align: center;

      input {
        text-align: center;
      }
    }

    input.ingredient-name {
      width: 100%;
    }

    .input.ingredient-name {
      width: 100%;
    }

    .input.grams {
      width: 30%;
    }

    .input.calories {
      width: 30%;
    }

  }
}

.buttons {
    display: flex;
    gap: 8px;
}
</style>

